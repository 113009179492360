<template>
  <section class="discounted-products new-products container">
    <div class="d-flex justify-content-between mb-1 mb-sm-4">
      <div class="mt-1">
        <img src="../../../../assets/images/circle.png" alt="" />
        <!-- <div class="circle1 rounded-circle mt-1 d-inline-block"></div> -->
        <h3 class="d-inline-block">
          <span class="weight-bold text-color-444 pr-2">محصولات</span>
          <span class="text-color-themeBlue  weight-bold"> جدید</span>
        </h3>
      </div>
      <div class="line mx-2 mt-3"></div>
      <div class="d-flex mt-1">
        <button class="position-relative arrow-circle  rounded-circle d-block">
          <div class="swiper-button-next"></div>
        </button>
        <button class="position-relative arrow-circle   rounded-circle d-block mr-2">
          <div class="swiper-button-prev"></div>
        </button>
      </div>
    </div>

    <div
      v-if="!disabledSliders.includes('newProducts')"
      class="swiper-container px-0 py-3 mb-4"
    >
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in newProducts.data"
          :key="index"
          class="swiper-slide discounted-products-item"
        >
          <product :product="item" :newTab="true"/>
        </div>
      </div>
    </div>

    <router-link
      to="/products?sort=newest"
      class="bg-color-themeBlue rounded-pill py-2 px-4 fontsize12  text-white d-table mx-auto"
    >
      محصولات بیشتر
    </router-link>
  </section>
</template>

<script>
import Product from "../product";
export default {
  name: "NewProducts",
  components: { Product },
  props: {
    newProducts: Object,
  },
};
</script>

<style scoped></style>
