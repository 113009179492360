<template>
  <section id="top-slider" class=" mb-sm-3 top-slider">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <a
          :href="slider ? slider.link : '#'"
          v-for="(slider, index) in sliders"
          :key="index"
          class="swiper-slide"
        >
          <img v-if="slider" :src="slider.upload" :alt="slider.title" />

          <div class="slider-grid container position-relative">
            <div class="top-slider-item d-flex flex-column text-right">
              <div
                v-if="slider && slider.discount_amount"
                class="fontsize12 text-white mb-3"
              >
                <span class="bg-color-red py-sm-0 px-sm-3 px-2 rounded-pill"
                  >{{ slider.discount_amount }}% تخفیف
                </span>
              </div>
              <h2
                style="font-size: 1.25rem"
                v-if="slider"
                class="weight-bold mb-3 text-color-444"
              >
                {{ slider.title }}
              </h2>
              <b-skeleton v-if="!slider"></b-skeleton>
              <p v-if="slider" class="fontsize12 text-color-666 text-justify">
                {{ slider.description }}
              </p>
              <b-skeleton v-if="!slider"></b-skeleton>
              <b-skeleton v-if="!slider"></b-skeleton>
              <b-skeleton v-if="!slider"></b-skeleton>

              <div
                v-if="slider && slider.link"
                class="d-flex justify-content-between"
              >
                <button
                  v-if="slider && slider.show_button"
                  type="button"
                  class="glow bg-color-themeBlue rounded-pill py-sm-2 py-1 px-sm-3 px-2 text-white fontsize12"
                >
                  سفارش محصول
                </button>
                <button
                  v-if="slider.title"
                  type="button"
                  class="top-slider-more-btn py-sm-2 py-1 fontsize12 text-color-666"
                >
                  اطلاعات بیشتر...
                  <svg
                    class="mt-1 mr-1"
                    width="13"
                    height="13"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5 19L8.5 12L15.5 5"
                      stroke="#666"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="h-100 position-relative d-md-none">
      <!-- Add Pagination -->
      <!-- Add Arrows -->
      <div class="swiper-button-next"></div>
      <div class="swiper-pagination d-flex flex-column"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper/swiper-bundle.js";

export default {
  name: "TopSlider",
  computed: {
    sliders() {
      let sliders = this.$store.state.header.sliders;
      if (!sliders || sliders[0] === null) {
        return [null];
      }
      if (this.isMobile) {
        return sliders.filter((i) => i.type == "mobile");
      } else {
        return sliders.filter((i) => i.type == "desktop");
      }
    },
    isMobile() {
      return document.body.offsetWidth < 767;
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      let topSliderH = document.querySelector(".top-slider");
      let width = Math.min(document.body.offsetWidth, 1800);
      if (topSliderH) {
        if (this.isMobile) {
          topSliderH.style.height = width / 2 + "px";
        } else {
          topSliderH.style.height = width / 3 + "px";
        }
      }
    },
    initHeaderSlider(kill = false) {
      this.initSwiper("headerSlider", kill, () => {
        new Swiper(".top-slider .swiper-container", {
          spaceBetween: 30,
          //   centeredSlides: true,
          loop: false,
          speed: 1000,
          autoplay: {
            delay: 4000,
            disableOnInteraction: true,
          },
          pagination: {
            el: ".top-slider .swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".top-slider .swiper-button-next",
            prevEl: ".top-slider .swiper-button-prev",
          },
        });
        this.$nextTick(() => this.onResize());
      });
    },
  },
};
</script>

<style scoped></style>
