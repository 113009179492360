<template>
  <Recommendation v-if="products && products.length" title1="محصولات" title2="مورد پسند من" :products="products" name="favorites"/>
</template>
<script>
import Recommendation from "./Recommendation";
export default {
  name: "FavoriteProducts",
  components: {
    Recommendation
  },
  computed: {
    products() {
      return this.$store.state.header ? this.$store.state.header.favorites.map(i => i.product) : [];
    }
  }
}
</script>

<style scoped>

</style>
