<template>
  <div class="w-100 big-banner-2 mb-4 mb-sm-2">
    <section class="products-categories container mb-5">
      <article class="products-categories-item1">
        <a
          :href="
            $store.state.header.banners.filter(
              (item) => item.position == 'a3'
            )[0].link || '#'
          "
          :class="{
            'bg-gray': !$store.state.header.banners.filter(
              (item) => item.position == 'a3'
            )[5].image,
          }"
        >
          <img
            v-if="
              $store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[0].image
            "
            :src="
              $store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[0].image
            "
            :alt-="$store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[0].image_alt"
          />
        </a>
      </article>
      <article class="products-categories-item2">
        <a
          :href="
            $store.state.header.banners.filter(
              (item) => item.position == 'a3'
            )[1].link || '#'
          "
          :class="{
            'bg-gray': !$store.state.header.banners.filter(
              (item) => item.position == 'a3'
            )[5].image,
          }"
        >
          <img
            v-if="
              $store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[1].image
            "
            :src="
              $store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[1].image
            "
            :alt-="$store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[1].image_alt"
          />
        </a>
      </article>
      <article class="products-categories-item3">
        <a
          :href="
            $store.state.header.banners.filter(
              (item) => item.position == 'a3'
            )[2].link || '#'
          "
          :class="{
            'bg-gray': !$store.state.header.banners.filter(
              (item) => item.position == 'a3'
            )[5].image,
          }"
        >
          <img
            v-if="
              $store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[2].image
            "
            :src="
              $store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[2].image
            "
             :alt-="$store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[2].image_alt"
          />
        </a>
      </article>
      <article class="products-categories-item4">
        <a
          :href="
            $store.state.header.banners.filter(
              (item) => item.position == 'a3'
            )[3].link || '#'
          "
          :class="{
            'bg-gray': !$store.state.header.banners.filter(
              (item) => item.position == 'a3'
            )[5].image,
          }"
        >
          <img
            v-if="
              $store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[3].image
            "
            :src="
              $store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[3].image
            "
            :alt-="$store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[3].image_alt"
          />
        </a>
      </article>
      <article class="products-categories-item5">
        <a
          :href="
            $store.state.header.banners.filter(
              (item) => item.position == 'a3'
            )[4].link || '#'
          "
          :class="{
            'bg-gray': !$store.state.header.banners.filter(
              (item) => item.position == 'a3'
            )[5].image,
          }"
        >
          <img
            v-if="
              $store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[4].image
            "
            :src="
              $store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[4].image
            "
            :alt-="$store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[4].image_alt"
          />
        </a>
      </article>
      <article class="products-categories-item6">
        <a
          :href="
            $store.state.header.banners.filter(
              (item) => item.position == 'a3'
            )[5].link || '#'
          "
          :class="{
            'bg-gray': !$store.state.header.banners.filter(
              (item) => item.position == 'a3'
            )[5].image,
          }"
        >
          <img
            v-if="
              $store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[5].image
            "
            :src="
              $store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[5].image
            "
            :alt-="
              $store.state.header.banners.filter(
                (item) => item.position == 'a3'
              )[5].image_alt"
          />
        </a>
      </article>
    </section>
    <a
      v-for="banner in $store.state.header.banners.filter(
        (item) => item.position == 'a2-mobile'
      )"
      target="_blank"
      :key="'banner' + banner.id"
      :href="banner.link"
      class="d-md-none"
    >
      <img
        class="border-radius7"
        v-if="banner.image"
        :src="banner.image"
        :alt-="banner.image_alt"
      />
      <b-skeleton-img v-else></b-skeleton-img>
    </a>
  </div>
</template>

<script>
export default {
  name: "ManyBannersGrid",
};
</script>
<style scoped>
@media screen and (max-width: 768px) {
  .big-banner-2 {
    padding: 6px;
    display: grid;
    grid-gap: 6px;
    grid-template-columns: 1fr 1fr;
  }
  .big-banner-2 > a {
    height: unset !important;
  }
}
</style>
