var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 big-banner-2 mb-4 mb-sm-2"},[_c('section',{staticClass:"products-categories container mb-5"},[_c('article',{staticClass:"products-categories-item1"},[_c('a',{class:{
          'bg-gray': !_vm.$store.state.header.banners.filter(
            function (item) { return item.position == 'a3'; }
          )[5].image,
        },attrs:{"href":_vm.$store.state.header.banners.filter(
            function (item) { return item.position == 'a3'; }
          )[0].link || '#'}},[(
            _vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[0].image
          )?_c('img',{attrs:{"src":_vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[0].image,"alt-":_vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[0].image_alt}}):_vm._e()])]),_c('article',{staticClass:"products-categories-item2"},[_c('a',{class:{
          'bg-gray': !_vm.$store.state.header.banners.filter(
            function (item) { return item.position == 'a3'; }
          )[5].image,
        },attrs:{"href":_vm.$store.state.header.banners.filter(
            function (item) { return item.position == 'a3'; }
          )[1].link || '#'}},[(
            _vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[1].image
          )?_c('img',{attrs:{"src":_vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[1].image,"alt-":_vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[1].image_alt}}):_vm._e()])]),_c('article',{staticClass:"products-categories-item3"},[_c('a',{class:{
          'bg-gray': !_vm.$store.state.header.banners.filter(
            function (item) { return item.position == 'a3'; }
          )[5].image,
        },attrs:{"href":_vm.$store.state.header.banners.filter(
            function (item) { return item.position == 'a3'; }
          )[2].link || '#'}},[(
            _vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[2].image
          )?_c('img',{attrs:{"src":_vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[2].image,"alt-":_vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[2].image_alt}}):_vm._e()])]),_c('article',{staticClass:"products-categories-item4"},[_c('a',{class:{
          'bg-gray': !_vm.$store.state.header.banners.filter(
            function (item) { return item.position == 'a3'; }
          )[5].image,
        },attrs:{"href":_vm.$store.state.header.banners.filter(
            function (item) { return item.position == 'a3'; }
          )[3].link || '#'}},[(
            _vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[3].image
          )?_c('img',{attrs:{"src":_vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[3].image,"alt-":_vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[3].image_alt}}):_vm._e()])]),_c('article',{staticClass:"products-categories-item5"},[_c('a',{class:{
          'bg-gray': !_vm.$store.state.header.banners.filter(
            function (item) { return item.position == 'a3'; }
          )[5].image,
        },attrs:{"href":_vm.$store.state.header.banners.filter(
            function (item) { return item.position == 'a3'; }
          )[4].link || '#'}},[(
            _vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[4].image
          )?_c('img',{attrs:{"src":_vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[4].image,"alt-":_vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[4].image_alt}}):_vm._e()])]),_c('article',{staticClass:"products-categories-item6"},[_c('a',{class:{
          'bg-gray': !_vm.$store.state.header.banners.filter(
            function (item) { return item.position == 'a3'; }
          )[5].image,
        },attrs:{"href":_vm.$store.state.header.banners.filter(
            function (item) { return item.position == 'a3'; }
          )[5].link || '#'}},[(
            _vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[5].image
          )?_c('img',{attrs:{"src":_vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[5].image,"alt-":_vm.$store.state.header.banners.filter(
              function (item) { return item.position == 'a3'; }
            )[5].image_alt}}):_vm._e()])])]),_vm._l((_vm.$store.state.header.banners.filter(
      function (item) { return item.position == 'a2-mobile'; }
    )),function(banner){return _c('a',{key:'banner' + banner.id,staticClass:"d-md-none",attrs:{"target":"_blank","href":banner.link}},[(banner.image)?_c('img',{staticClass:"border-radius7",attrs:{"src":banner.image,"alt-":banner.image_alt}}):_c('b-skeleton-img')],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }